











































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { getLanguageTypeQueryList, languageResourceAdd, languageResourceUpdate } from '@/api/system/LanguageApi';
import * as LangApi from '@/api/system/LanguageApi';

@Component({
  name: 'I18nForm',
  components: {}
})
export default class I18nForm extends Vue {
  // ref
  @Ref() readonly formRef;

  // prop
  // 弹窗显隐
  @Prop({ default: false }) visible!: boolean;
  // 语言id
  @Prop({ default: '' }) langId!: string;
  @Prop({ default: '' }) langCode!: string;

  // 静态标识
  @Prop({ default: true }) staticFlag!: boolean;

  data: string;

  // 语言新增/编辑表单实体
  formData: any = {
    code: '',
    staticFlag: true,
    contents: []
  };
  // 表单加载状态
  formLoading: boolean = false;
  // 表单验证规则
  formRules: Record<string, any> = {
    code: {
      required: true,
      message: this.$t('lang_please_fill_in_code'),
      trigger: 'blur'
    }
  };

  // 语言类型下拉列表
  languageTypeList: any[] = [];

  /**
   * 获取语言类型下拉列表
   */
  getLanguageTypeList() {
    this.formLoading = true;
    getLanguageTypeQueryList()
      .then((res: any) => {
        if (res.code == '1') {
          this.languageTypeList = res.data;

          if (this.langId) {
            // 如果是编辑
            this.formData = Object.assign({}, this.data);

            this.disabledLanguageType();
          } else {
            this.formData.staticFlag = this.staticFlag;
            this.formData.code = 'lang_';

            // 如果是新增则添加全部语言到选项
            this.languageTypeList.forEach(item => {
              this.formData.contents.push({
                languageType: item.id,
                content: ''
              });
            });
          }
        }
      })
      .finally(() => {
        this.formLoading = false;
      });
  }

  /**
   * 添加一条
   */
  addContents() {
    this.formData.contents.push({});
    this.disabledLanguageType();
  }

  /**
   * 前端禁用语言类型防止一条语言添加多个
   */
  disabledLanguageType() {
    this.languageTypeList.forEach(item => (item.disabled = false));
    if (this.formData.contents && this.formData.contents.length > 0) {
      this.formData.contents.forEach(item => {
        if (item.languageType) {
          for (let row of this.languageTypeList) {
            if (row.id === item.languageType) {
              row.disabled = true;
            }
          }
        }
      });
    }
  }

  /**
   * 删除一条
   * @param index
   */
  deleteContents(index) {
    this.formData.contents.splice(index, 1);
  }

  @Watch('formData', { deep: true })
  formDataWatcher(val) {
    this.disabledLanguageType();
  }

  /**
   * 监听弹窗显示隐藏
   * @param val
   */
  @Watch('visible')
  visibleWatcher(val) {
    if (val) {
      if (this.langCode) {
        this.getLanguageDetail().then((res: any) => {
          if (res.code == '1') {
            this.data = res.data;
            this.getLanguageTypeList();
          }
        });
      } else {
        this.getLanguageTypeList();
      }
    } else {
      // 清空表单内容
      this.formData = {
        code: 'lang_',
        staticFlag: this.staticFlag,
        contents: []
      };
      // 清理表单验证
      this.formRef.resetFields();
    }
  }

  getTitle(): string {
    return this.langId ? (this.$t('lang_language_edit_resources') as string) : (this.$t('lang_language_new_resources') as string);
  }

  async getLanguageDetail() {
    const data = await LangApi.languageResourceDetail({ code: this.langCode });
    return data;
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  /**
   * update visible
   * @param value
   */
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  /**
   * 保存表单
   */
  saveForm() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      let result;

      let params = Object.assign({}, this.formData);
      // 过滤未填写的语言内容
      if (params.contents && params.contents.length > 0) {
        params.contents = params.contents.filter(item => item.content && item.content.length > 0);
      }

      if (this.langId) {
        result = languageResourceUpdate(params);
      } else {
        result = languageResourceAdd(params);
      }
      result
        .then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            // 关闭弹窗
            this.$emit('success', res.data);
            this.updateVisible(false);
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          // 关闭加载框
        });
    });
  }

  get dialogVisible() {
    return this.visible;
  }

  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
