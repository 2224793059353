



























































































import { Component, Vue } from 'vue-property-decorator';
import I18nForm from '@/views/system/i18n-form.vue';
import {
  languageResourceDelete,
  languageResourceQueryPage,
  languageResourceRefresh,
  valueItemsrefresh,
  deletei18n
} from '@/api/system/LanguageApi';
import { clearI18nLocalStorageCache } from '@/lang';

@Component({
  name: 'I18nList',
  components: {
    I18nForm
  }
})
export default class I18nList extends Vue {
  // 唯一码搜索匹配类型
  codeSearchMatchType: number = 1;
  // 表单搜索Model
  searchForm: any = { staticFlag: true };
  // 列表加载状态
  tableLoading: boolean = false;
  // 语言资源列表
  languageResourceList: any[] = [];
  // 当前选中语言资源
  currentLanguage: any = {};
  // 语言id
  langId: string = '';

  // 是否显示新增/编辑语言资源弹窗
  i18nFormDiglogVisible: boolean = false;

  // 分页参数
  pageIndex: number = 1;
  // 每页大小
  pageSize: number = 20;
  // 总条数
  total: number = 0;
  langCode: string = '';

  /**
   * onMounted
   */
  mounted() {
    // 获取语言资源列表
    this.getLanguageResourceList();
  }

  /**
   * 搜索
   */
  handleSearch() {
    this.pageIndex = 1;
    this.getLanguageResourceList();
  }

  /**
   * 重置搜索
   */
  resetSearch() {
    // 置空搜索表单
    this.pageIndex = 1;
    this.searchForm = { staticFlag: true };
    this.getLanguageResourceList();
  }

  /**
   * 获取语言资源列表
   */
  getLanguageResourceList() {
    let searchParams = Object.assign({}, this.searchForm);
    if (this.codeSearchMatchType === 1) {
      delete searchParams.codeLike;
    } else if (this.codeSearchMatchType === 2) {
      searchParams.codeLike = searchParams.code;
      delete searchParams.code;
    }
    let params = {
      ...searchParams,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.tableLoading = true;
    languageResourceQueryPage(params)
      .then((res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.languageResourceList = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        this.tableLoading = false;
      });
  }

  /**
   * 改变分页大小
   */
  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.getLanguageResourceList();
  }

  /**
   * 跳转至某页
   */
  changePage(page: number) {
    this.pageIndex = page;
    this.getLanguageResourceList();
  }

  /**
   * 打开语言资源编辑弹窗
   */
  openLanguageForm(row?: any) {
    if (row) {
      this.langId = row.id;
      this.langCode = row.code;
    }
    this.i18nFormDiglogVisible = true;
  }

  /**
   * 删除语言资源
   * @param row
   */
  deleteLanguageById(row?: any) {
    if (row) {
      this.currentLanguage = row;
      this.$confirm(this.$t('lang_whether_or_not_to_delete') as string, this.$t('lang_tips') as string, {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        type: 'warning'
      })
        .then(() => {
          languageResourceDelete({ id: row.id })
            .then((res: Record<any, any>) => {
              if (res.code == '1') {
                this.$message.success(res.message);
                this.getLanguageResourceList();
              } else {
                this.$message.error(res.message);
              }
            })
            .finally(() => {});
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: this.$t('lang_undeleted') as string
          });
        });
    }
  }

  openLanguageType() {
    this.$router.push({ path: '/system/i18nLanguageType' });
  }

  /**
   * 刷新缓存
   */
  clearI18nCache() {
    languageResourceRefresh()
      .then((res: Record<any, any>) => {
        if (res.code === '1') {
          // 清理浏览器语言资源缓存
          clearI18nLocalStorageCache();
          // 刷新页面
          window.location.reload();
        }
      })
      .finally(() => {});
  }
  /**
   * 刷新静态数据
   */
  clearStaticData() {
    valueItemsrefresh()
      .then((res: Record<any, any>) => {
        if (res.code === '1') {
          this.$message.success('刷新成功');
        }
      })
      .finally(() => {});
  }
  /**
   * 刷新缓存
   */
  clearMultilingual() {
    deletei18n()
      .then((res: Record<any, any>) => {
        if (res.code === '1') {
          // 清理浏览器语言资源缓存
          clearI18nLocalStorageCache();
          // 刷新页面
          window.location.reload();
        }
      })
      .finally(() => {});
  }
}
